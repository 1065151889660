import React from "react"
import { Link } from "gatsby"

import { Navbar, Nav, Image } from "react-bootstrap"
import Logo from "./../images/Logo-Mobile.svg"
import BackBtn from "./../images/back-btn.svg"
import BackBtnEn from "./../images/back-btn-en.svg"

const CustomNavbar = ({ pageInfo, backButton, enSite }) => {
  const bgText = {
    url: "/",
    logoAlt: "Вита Херб",
    backAlt: "Назад",
    lang: "ЕЗИК",
    products: "ПРОДУКТИ",
    herbalProd: "БИЛКОВИ ПРОДУКТИ O’YES VITA",
    lutein: "/lutein-complex",
    hepatofelin: "/hepatofelin",
    proplus: "/prostatizal-plus",
    psorplus: "/psoriazal-plus",
    laksiprokt: {
      name: "Лаксипрокт",
      link: "/laksiprokt",
    },
    liporedukt: {
      name: "Липоредукт",
      link: "/liporedukt",
    },
    menopauzal: {
      name: "Менопаузал",
      link: "/menopauzal",
    },
    miomal: {
      name: "Миомал",
      link: "/miomal",
    },
    nevroherb: {
      name: "Неврохерб",
      link: "/nevroherb",
    },
    pielonefral: {
      name: "Пиелонефрал",
      link: "/pielonefral",
    },
    prostatizal: {
      name: "Простатизал",
      link: "/prostatizal",
    },
    psoriazal: {
      name: "Псориазал",
      link: "/psoriazal",
    },
    veniprotekt: {
      name: "Венипротект",
      link: "/veniprotekt",
    },
    articles: {
      text: "Статии",
      link: "/articles",
    },
    technology: {
      text: "Технология",
      link: "/technology",
    },
    privacy: {
      text: "Защита на личните данни",
      link: "/privacy",
    },
    about: {
      text: "За Вита Херб",
      link: "/about",
    },
    contact: {
      text: "Контакти",
      link: "/contact",
    },
    sitemap: {
      text: "Карта на сайта",
      link: "/site-map",
    },
  }

  const enText = {
    url: "/en",
    logoAlt: "Vita Herb",
    backAlt: "back",
    lang: "LANGUAGE",
    products: "PRODUCTS",
    herbalProd: "HERBAL PRODUCTS O’YES VITA",
    lutein: "/en/lutein-complex",
    hepatofelin: "/en/hepatofelin",
    proplus: "/en/prostatizal-plus",
    psorplus: "/en/psoriazal-plus",
    laksiprokt: {
      name: "Laxiproct",
      link: "/en/laksiprokt",
    },
    liporedukt: {
      name: "Liporeduct",
      link: "/en/liporedukt",
    },
    menopauzal: {
      name: "Menopauzal",
      link: "/en/menopauzal",
    },
    miomal: {
      name: "Miomal",
      link: "/en/miomal",
    },
    nevroherb: {
      name: "Nevroherb",
      link: "/en/nevroherb",
    },
    pielonefral: {
      name: "Pielonefral",
      link: "/en/pielonefral",
    },
    prostatizal: {
      name: "Prostatizal",
      link: "/en/prostatizal",
    },
    psoriazal: {
      name: "Psoriazal",
      link: "/en/psoriazal",
    },
    veniprotekt: {
      name: "Veniprotect",
      link: "/en/veniprotekt",
    },
    articles: {
      text: "Articles",
      link: "/en/articles",
    },
    technology: {
      text: "Technology",
      link: "/en/technology",
    },
    privacy: {
      text: "Data privacy",
      link: "/en/privacy",
    },
    about: {
      text: "About Vita Herb",
      link: "/en/about",
    },
    contact: {
      text: "Contacts",
      link: "/en/contact",
    },
    sitemap: {
      text: "Site map",
      link: "/en/site-map",
    },
  }

  let back
  enSite ? (back = BackBtnEn) : (back = BackBtn)

  let homepage
  enSite ? (homepage = "/en") : (homepage = "/")

  let currentLang
  enSite ? (currentLang = enText) : (currentLang = bgText)

  return (
    <>
      <Navbar collapseOnSelect expand="true" className="bg-white shadow-1">
        <div className="d-flex flex-grow-1">
          {backButton ? (
            <Link to={homepage} className="d-md-none my-auto w-100">
              <img src={back} alt={currentLang.backAlt} className="back-btn " />
            </Link>
          ) : (
            <span className="d-md-none my-auto w-100"></span>
          )}
          <span className="d-none d-md-block w-100 d-block my-auto"></span>
          <Navbar.Brand>
            <Link to={currentLang.url}>
              <Image src={Logo} alt={currentLang.logoAlt} className="py-2" />
            </Link>
          </Navbar.Brand>
          <span className="w-100 nav-div d-block d-md-none"></span>
          <div className="w-100 text-right my-auto nav-div">
            <Navbar.Toggle className="" aria-controls="basic-navbar-nav" />
          </div>
        </div>
        <Navbar.Collapse className="" id="basic-navbar-nav">
          <Nav
            className="mr-auto menu"
            activeKey={pageInfo && pageInfo.pageName}
          >
            <div className="h6-small menu-divider">{currentLang.lang}</div>
            <Nav.Item className="menu-link">
              <Link to="/en">English</Link>
            </Nav.Item>

            <Nav.Item className="menu-link">
              <Link to="/">Български</Link>
            </Nav.Item>

            <div className="h6-small menu-divider">{currentLang.products}</div>
            <Nav.Item className="menu-link">
              <Link to={currentLang.lutein}>Lutein Complex Plus</Link>
            </Nav.Item>
            <Nav.Item className="menu-link">
              <Link to={currentLang.hepatofelin}>Hepatofelin</Link>
            </Nav.Item>
            <Nav.Item className="menu-link">
              <Link to={currentLang.proplus}>Prostatizal Plus</Link>
            </Nav.Item>
            <Nav.Item className="menu-link">
              <Link to={currentLang.psorplus}>Psoriazal Plus</Link>
            </Nav.Item>
            <div className="h6-small menu-divider">
              {currentLang.herbalProd}
            </div>
            <Nav.Item className="menu-link">
              <Link to={currentLang.laksiprokt.link}>
                {currentLang.laksiprokt.name}
              </Link>
            </Nav.Item>
            <Nav.Item className="menu-link">
              <Link to={currentLang.liporedukt.link}>
                {currentLang.liporedukt.name}
              </Link>
            </Nav.Item>
            <Nav.Item className="menu-link">
              <Link to={currentLang.menopauzal.link}>
                {currentLang.menopauzal.name}
              </Link>
            </Nav.Item>
            <Nav.Item className="menu-link">
              <Link to={currentLang.miomal.link}>
                {currentLang.miomal.name}
              </Link>
            </Nav.Item>
            <Nav.Item className="menu-link">
              <Link to={currentLang.nevroherb.link}>
                {currentLang.nevroherb.name}
              </Link>
            </Nav.Item>
            <Nav.Item className="menu-link">
              <Link to={currentLang.pielonefral.link}>
                {currentLang.pielonefral.name}
              </Link>
            </Nav.Item>
            <Nav.Item className="menu-link">
              <Link to={currentLang.prostatizal.link}>
                {currentLang.prostatizal.name}
              </Link>
            </Nav.Item>
            <Nav.Item className="menu-link">
              <Link to={currentLang.psoriazal.link}>
                {currentLang.psoriazal.name}
              </Link>
            </Nav.Item>
            <Nav.Item className="menu-link">
              <Link to={currentLang.veniprotekt.link}>
                {currentLang.veniprotekt.name}
              </Link>
            </Nav.Item>
            <div className="h6-small menu-divider"></div>

            {!enSite && (
              <Nav.Item className="menu-link">
                <Link to={currentLang.articles.link}>
                  {currentLang.articles.text}
                </Link>
              </Nav.Item>
            )}
            <Nav.Item className="menu-link">
              <Link to={currentLang.technology.link}>
                {currentLang.technology.text}
              </Link>
            </Nav.Item>
            <Nav.Item className="menu-link">
              <Link to={currentLang.privacy.link}>
                {currentLang.privacy.text}
              </Link>
            </Nav.Item>
            <Nav.Item className="menu-link">
              <Link to={currentLang.about.link}>{currentLang.about.text}</Link>
            </Nav.Item>
            <Nav.Item className="menu-link">
              <Link to={currentLang.contact.link}>
                {currentLang.contact.text}
              </Link>
            </Nav.Item>
            <Nav.Item className="menu-link">
              <Link to={currentLang.sitemap.link}>
                {currentLang.sitemap.text}
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default CustomNavbar
