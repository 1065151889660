/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"

import Header from "./header"
import Footer from "./footer"


const Layout = ({
  children,
  pageInfo,
  backButton,
  announce,
  announceLink,
  enSite,
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Container fluid>
          <Row className="justify-content-center mb-4">
            <Col className="px-0">
              <Header
                pageInfo={pageInfo}
                backButton={backButton}
                announce={announce}
                announceLink={announceLink}
                enSite={enSite}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Container fluid className="px-0">
                <main>{children}</main>
              </Container>
            </Col>
          </Row>
        </Container>
        <Footer enSite={enSite} />
      </>
    )}
  />
)

export default Layout
