//import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Navbar from "./navBar"


const Header = ({ announce, announceLink, pageInfo, backButton, enSite }) => (
  <header>
    <div
      style={{
        margin: `0 auto`,
        height: `24px`,
        padding: `0.25rem`,
        backgroundColor: `#fff9f7`,
        borderBottom: `solid 1px #E8DCD5`,
      }}
    >
      <p
        style={{
          fontSize: `0.75rem`,
          textAlign: `center`,
          margin: 0,
        }}
      >
        <a
          href={announceLink}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: `#484349`,
            textDecoration: `none`,
          }}
        >
          {announce}
        </a>
      </p>
    </div>
    <Navbar backButton={backButton} enSite={enSite} pageInfo={pageInfo} />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
